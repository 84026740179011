import Constants from '../constants'

export default class Leads {
  id = "Leads";

  constructor() {
    this.setup()
  }

  setup() {
    document[this.id] = {
      clear: this.clear
    }
  }

  clear() {
    var input = document.getElementById("email");

    input.placeholder = '';
    input.value = '';
    input.classList.remove('has_error');
    input.classList.remove('success');
  }
} 