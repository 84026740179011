export default {
  SLIDER_CLASSES: {
    // HEADER_CATEGORY_CAROUSEL: '.category-glide',
    CHOSEN_ITEMS_CAROUSEL: '.chosen-items-glide',
    BRAND_CARDS_CAROUSEL: '.brand-cards-carousel-glide',
    PRODUCT_CARDS_CAROUSEL: '.product-cards-carousel-glide',
    BRAND_CARDS_SLIDER: '.brand-cards-slider-glide',
    COMPANIES_LOGOS_CAROUSEL: '.companies-logos-carousel',
    PRODUCT_CHOSEN_ITEMS_CAROUSEL: '.product-chosen-items-carousel'
  },
  SIDE_BAR_MENU: {
    TOGGLE_CLASS: 'focused',
    COMPONENT_ID: 'side-bar-menu',
    SUB_ITEM: {
      TOGGLE_CLASS: 'focused',
    }
  },
  SIDE_BAR_FILTER: {
    TOGGLE_CLASS: 'focused',
    SUB_ITEM: {
      TOGGLE_CLASS: 'focused',
    }
  },
  ORDER_FILTER_MODAL: {
    TOGGLE_CLASS: 'focused',
  },
  HEADER_BAR_CATEGORIES: {
    TOGGLE_CLASS: 'focused',
    COMPONENT_ID: 'category-focus-container'
  }
  // HEADER_SEARCH_FIXED_BACKGROUND_ID: 'search-fixed-background'
}