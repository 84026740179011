import Constants from '../constants'

export default class HeaderBarCategories {
  id = "HeaderBarCategories";
  lastSelectedSubItem = null;
  lockCategoriesAnimation = false;

  constructor() {
    this.setup()
  }

  setup() {
    document[this.id] = {
      toggleOpenClose: this.toggleOpenClose,
    }
  }

  toggleOpenClose(id) {
    if(this.lockCategoriesAnimation) return;

    let currentSelectedSubItem = document.getElementById(id);
    
    if(this.lastSelectedSubItem != currentSelectedSubItem && this.lastSelectedSubItem != null) {
      this.lastSelectedSubItem.classList.remove(Constants.HEADER_BAR_CATEGORIES.TOGGLE_CLASS);

      currentSelectedSubItem.classList.add(Constants.HEADER_BAR_CATEGORIES.TOGGLE_CLASS);

      this.lastSelectedSubItem = currentSelectedSubItem;
      return;
    }

    if(currentSelectedSubItem.classList.contains(Constants.HEADER_BAR_CATEGORIES.TOGGLE_CLASS)) {
      currentSelectedSubItem.classList.remove(Constants.HEADER_BAR_CATEGORIES.TOGGLE_CLASS);
    } else {
      currentSelectedSubItem.classList.add(Constants.HEADER_BAR_CATEGORIES.TOGGLE_CLASS);
    }

    this.lastSelectedSubItem = currentSelectedSubItem;
  }
}