import Constants from '../constants'

export default class SideBarMenu {
  id = "SideBarMenu";

  constructor() {
    this.setup()
  }

  setup() {
    document[this.id] = {
      open: this.openSideBar,
      close: this.closeSideBar,
      toggleSideBarItem: this.toggleSideBarItem
    }
  }

  openSideBar() {
    let sideBarMenu = document.getElementById(Constants.SIDE_BAR_MENU.COMPONENT_ID)
    sideBarMenu.classList.add(Constants.SIDE_BAR_MENU.TOGGLE_CLASS);
  }

  closeSideBar() {
    let sideBarMenu = document.getElementById(Constants.SIDE_BAR_MENU.COMPONENT_ID)
    sideBarMenu.classList.remove(Constants.SIDE_BAR_MENU.TOGGLE_CLASS);
  }

  toggleSideBarItem(id, arrowId) {
    let selectedSubItem = document.getElementById(id)
    let arrowIdItem = document.getElementById(arrowId)

    if(selectedSubItem.classList.contains(Constants.SIDE_BAR_MENU.TOGGLE_CLASS)) {
      selectedSubItem.classList.remove(Constants.SIDE_BAR_MENU.TOGGLE_CLASS);
      arrowIdItem.classList.add('down');
      arrowIdItem.classList.remove('up');
    } else {
      selectedSubItem.classList.add(Constants.SIDE_BAR_MENU.TOGGLE_CLASS);
      arrowIdItem.classList.remove('down');
      arrowIdItem.classList.add('up');
    }
  }
}