import Constants from '../constants'
import { debounce } from 'lodash';

export default class Search {
  id = "Search";

  constructor() {
    this.setup()
  }

  setup() {
    document[this.id] = {
      // open: this.open,
      close: this.close,
      onType: this.onType,
      submit: this.submit,
      submitSearchCategory: debounce(this.submitSearchCategory, 500),
      submitSearchSubcategory: debounce(this.submitSearchSubcategory, 500),
      submitSearchBrands: debounce(this.submitSearchBrands, 500),
    }
  }

  // open() {
  //   let fixedBackgroundContainer = document.getElementById(Constants.HEADER_SEARCH_FIXED_BACKGROUND_ID)
  //   fixedBackgroundContainer.classList.add('focused');
  // }

  close(searchContainerId, autocompleteId) {
    Search.toggleAutocomplete(false, searchContainerId, autocompleteId);
    //let fixedBackgroundContainer = document.getElementById(Constants.HEADER_SEARCH_FIXED_BACKGROUND_ID);

    if (fixedBackgroundContainer.classList.contains('focused')) {
      fixedBackgroundContainer.classList.remove('focused');
    }
  }

  onType(event, searchContainerId, autocompleteId) {
    if (event.target.value.length > 0) {
      Search.toggleAutocomplete(true, searchContainerId, autocompleteId);
    } else {
      Search.toggleAutocomplete(false, searchContainerId, autocompleteId);
    }
  }

  static toggleAutocomplete(show, searchContainerId, autocompleteId) {
    let searchContainer = document.getElementById(searchContainerId);
    let searchAutocomplete = document.getElementById(autocompleteId);

    if (show) {
      searchAutocomplete.classList.add('visible');
      searchContainer.classList.add('on-type');
    } else {
      searchAutocomplete.classList.remove('visible');
      searchContainer.classList.remove('on-type');
    }
  }

  submit(event) {
    let formSearch = event.target;
    let keywords = formSearch.querySelector('input[name="keywords"]').value;
    keywords = slugify(keywords);

    if (keywords) {
      window.location.href = `/search?keywords=${keywords}`
    }
  }

  submitSearchCategory() {
    let keywords = document.getElementById('search-category-keywords').value.trim();
  
      // Construa a URL com os parâmetros de busca
      let url = `/ajax/category?keywords=${encodeURIComponent(keywords)}`;
      fetch(url, {
        method: 'GET',
      })
      .then(response => response.text())
      .then(html => {
        document.getElementById('categories-search-list').innerHTML = html;
        let filterBrand = document.getElementById('filterCategory').value;
        let filterBrandArray = filterBrand.split(',');
        filterBrandArray.forEach(function(valor) {
          let checkbox = document.querySelector('input[type="checkbox"][value="'+valor+'"]');
          if (checkbox)  checkbox.checked = true; 
        })
      })
      .catch(error => {
        console.error('Erro ao buscar categorias:', error);
      });
  }  

  submitSearchSubcategory() {
    const input = document.getElementById('search-subcategory-keywords');
    const category = input.getAttribute('data-category');
    const keywords = input.value.trim();
  
    let url = `/ajax/subcategory?category=${encodeURIComponent(category)}&keywords=${encodeURIComponent(keywords)}`;
    fetch(url, {
      method: 'GET',
    })
    .then(response => response.text())
    .then(html => {
      document.getElementById('subcategories-search-list').innerHTML = html;
      let filterBrand = document.getElementById('filterSubcategory').value;
      let filterBrandArray = filterBrand.split(',');
      filterBrandArray.forEach(function(valor) {
        let checkbox = document.querySelector('input[type="checkbox"][value="'+valor+'"]');
        if (checkbox)  checkbox.checked = true; 
      })      
    })
    .catch(error => {
      console.error('Erro ao buscar subcategorias:', error);
    });
  }  

  submitSearchBrands() {
    let keywords = document.getElementById('search-brands-keywords').value.trim();
  
    if (keywords.length > 0) {
      // Construa a URL com os parâmetros de busca
      let url = `/ajax/brand?keywords=${encodeURIComponent(keywords)}`;
      fetch(url, {
        method: 'GET',
      })
      .then(response => response.text())
      .then(html => {
        document.getElementById('brands-search-list').innerHTML = html;
        let filterBrand = document.getElementById('filterBrand').value;
        let filterBrandArray = filterBrand.split(',');
        filterBrandArray.forEach(function(valor) {
          let checkbox = document.querySelector('input[type="checkbox"][value="'+valor+'"]');
          if (checkbox)  checkbox.checked = true; 
        })
      })
      .catch(error => {
        console.error('Erro ao buscar marcas:', error);
      });
    }
  }  

}

function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}
