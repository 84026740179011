import SideBarMenu from "../components/side-bar-menu";
import HeaderBar from "../components/header-bar-categories";
import Search from "../components/search";
import Leads from "../components/leads";
import Constants from "../constants";

class HomeScreen {
  static setup() {
    let homeScreen = new HomeScreen();
    let turbolinksHasBeenLoaded = false;

    document.addEventListener("turbolinks:load", function () {
      if (turbolinksHasBeenLoaded) return;
      turbolinksHasBeenLoaded = true;

      new SideBarMenu();
      new HeaderBar();
      new Search();
      new Leads();

      let companiesContainer = document.getElementsByClassName(
        "companies-logos-carousel"
      );
      if (companiesContainer && companiesContainer.length > 0) {
        homeScreen.setupCompaniesLogosCarousel();
      }
    });
  }

  setupCompaniesLogosCarousel() {
    tns({
      container: Constants.SLIDER_CLASSES.COMPANIES_LOGOS_CAROUSEL,
      items: 2,
      gutter: 25,
      autoplay: true,
      loop: true,
      center: true,
      controls: false,
      nav: false,
      autoplay: true,
      autoplayButtonOutput: false,
      preventScrollOnTouch: "force",
      responsive: {
        600: {
          items: 5,
          gutter: 120,
          mouseDrag: true,
          slideBy: 1,
          swipeAngle: false,
          speed: 400,
        },
      },
    });
  }
}

export default HomeScreen.setup();
