import Cookies from 'js-cookie'

class CookieBar {
  constructor() {
    this.modalBackdrop = document.getElementById("modalBackdrop");
  }

  init() {
    this.checkConsent();
    this.addButtonBehaviors();
  }

  checkConsent() {
    const allow_cookie = Cookies.get("allow_cookie");

    if (!allow_cookie) this.modalBackdrop.style.display = "flex";
  }

  addButtonBehaviors() {
    if (!this.modalBackdrop) {
      return;
    }

    this.modalBackdrop
      .querySelector(".accept")
      .addEventListener("click", (event) => {
        this.allowCookies(true);
      });

    this.modalBackdrop
      .querySelector(".manage")
      .addEventListener("click", (event) => {
        event.preventDefault();
        this.allowCookies(false);
      });

    this.modalBackdrop
      .querySelector(".aviso-rejeitar")
      .addEventListener("click", (event) => {
        event.preventDefault();
        this.setConsent({
          necessary: true,
          analytics: false,
          preferences: false,
          marketing: false,
        });
        location.reload();
        this.modalBackdrop.style.display = "none";
      });

    document
      .getElementById("aceitarTodos")
      .addEventListener("click", (event) => {
        this.allowCookies(true);
      });
  }

  allowCookies(allow) {
    if (allow) {
      this.setConsent({
        necessary: true,
        analytics: true,
        preferences: true,
        marketing: true,
      });
    } else {
      document.getElementById("cookies-bar").style.display = "none";
      document.getElementById("cookies-settings").style.display = "flex";
      this.modalBackdrop.style.alignItems = "center";

      const btnSave = document.getElementById("saveConfig");
      btnSave.removeEventListener("click", this.handleSaveClick);
      btnSave.addEventListener("click", this.handleSaveClick);

      document
        .getElementById("modalCloseBtn")
        .addEventListener("click", (event) => {
          document.getElementById("modalBackdrop").style.display = "none";
        });
    }

    this.modalBackdrop.classList.add("hidden");
  }

  handleSaveClick = (event) => {
    this.setConsent({
      necessary: true,
      analytics: document.getElementById("consent-analytics").checked,
      preferences: document.getElementById("consent-preferences").checked,
      marketing: document.getElementById("consent-marketing").checked,
    });

    document.getElementById("modalBackdrop").style.display = "none";
    this.modalBackdrop.classList.add("hidden");
  };

  setConsent(consent) {
    const consentMode = {
      functionality_storage: consent.preferences ? "granted" : "denied",
      security_storage: consent.necessary ? "granted" : "denied",
      ad_storage: consent.marketing ? "granted" : "denied",
      ad_user_data: consent.marketing ? "granted" : "denied",
      ad_personalization: consent.marketing ? "granted" : "denied",
      analytics_storage: consent.analytics ? "granted" : "denied",
      personalization_storage: consent.preferences ? "granted" : "denied",
    };

    window.gtag("consent", "update", consentMode);

    if (consentMode.ad_storage === "granted") {
      fbq("consent", "grant");
    }

    if (consentMode.ad_storage === "denied") {
      fbq("consent", "revoke");
    }

    Cookies.set("allow_cookie", JSON.stringify(consentMode), {
      expires: 3650,
    });
  }
}

window.onload = function () {
  const cookieBar = new CookieBar();

  cookieBar.init();
};
